import React, { useEffect } from 'react'
import AOS from 'aos'
import Link from 'next/link'
import { Col, Container, Row } from 'react-bootstrap'
import { SubText } from 'components/Common/Typography'
import ContactInformation from 'components/HomePage/ContactInformation'
import Image from 'next/image'
import LinkButton from 'components/LinkButton'

export async function getStaticProps() {
  return { props: { isErrorCls: true } }
}
const ErrorPage = () => {
  useEffect(() => {
    AOS.init()
  }, [])

  return (
    <>
      <section className="errorMain pt-120px pb-120px d-flex flex-column justify-content-center">
        <Container fluid>
          <Row className="mt-5 position-relative">
            <Col lg={12} xl={3} xxl={3} className="text-center text-xl-start">
              <span className="opps-error">Uh oh!</span>
            </Col>
            <Col lg={6} xl={5} xxl={4} className="text-center text-xl-start">
              <div className="error-img">
                <Image
                  src="/assets/images/404-error.png"
                  alt="not-found"
                  className="img-fluid"
                  width={524}
                  height={293}
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                  }}
                />
              </div>
              <h1 className="mb-4 title_sm_black">
                Looks like you have reached opposite side of Webclues!
              </h1>
              <SubText
                className="text_para mb-3"
                text="Let us help you to get out of this place safely!"
              />
              <div className="mt-3 mt-lg-4 mb-0 d-flex justify-content-center">
                <LinkButton className="mx-4 mb-4" path="/">
                  Go Back Home
                </LinkButton>
                <LinkButton className="mx-4 mb-4" path="/contact-us">
                  Contact Us
                </LinkButton>
              </div>
            </Col>
            <Col lg={6} xl={4} xxl={5}>
              <div className="text-center mt-4 mt-lg-0">
                <Image
                  src="/assets/images/not-found.svg"
                  alt="not-found"
                  className="img-fluid"
                  width={810}
                  height={630}
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                  }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <ContactInformation
        data={{
          title: 'Regional Contact Info',
          subTitle: 'We can give you the Wings to Fly. Contact us now!',
        }}
      />
    </>
  )
}
export default ErrorPage
