import Link from 'next/link'
import React, { ReactNode, MouseEvent } from 'react'
interface ButtonProps {
  className?: string
  path: string
  children: ReactNode
  tabIndex?: any
  dataAos?: any
  dataAosDelay?: any
  target?: any
  rel?: any
  onClick?: (e?: any) => any
  role?: any
}
const LinkButton: React.FC<ButtonProps> = ({
  className,
  path,
  children,
  tabIndex,
  dataAos,
  dataAosDelay,
  target,
  rel,
  onClick,
  role,
}) => {
  return (
    <>
      <Link
        href={path}
        className={`${className} btn btn--primary hover--animation`}
        tabIndex={tabIndex}
        data-aos={dataAos}
        data-aos-delay={dataAosDelay}
        target={target}
        rel={rel}
        onClick={onClick}
        role={role}
      >
        <span className="d-flex justify-content-center align-items-center">
          {children}
        </span>
      </Link>
    </>
  )
}

export default LinkButton
